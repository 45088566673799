import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { Title } from 'src/shared/ui';
import { Form, Input, Button, Card, Spin, Select, Menu, Row, Col, Typography } from 'antd';

import { CommissionSettingsKeys, TFormField } from './types';
import { $commission, initCommissionPage, submitUpdateCommissionData, getCommissionFx } from './model';
import { ffdSelectOptions } from './config';
import styles from './Commission.module.less';
import { FIELDS_MESSAGES, FORM_FIELDS, SUMM_AS_KOPEK_FIELDS } from './constants';

export const Commission = () => {
  const [form] = Form.useForm();
  const [selectedTab, setSelectedTab] = useState('/client');
  const commission = useStore($commission);
  const isLoading = useStore(getCommissionFx.pending);
  const [errors, setErrors] = useState<Record<string, string>>();

  const formSubmitHandler = (): void => {
    const values = form.getFieldsValue();
    const settings: { key: CommissionSettingsKeys; value: string }[] = [];
    let isError = false;
    setErrors(undefined);

    Object.keys(values).forEach((key) => {
      if (!String(values[key])) {
        isError = true;
        const err = FIELDS_MESSAGES.find(i => i.name === key)?.message;
        setErrors(prev => ({
          ...prev,
          ...(err && { [key]: err }),
        }));
      }

      const value =
        ((key.endsWith('_fixed') || SUMM_AS_KOPEK_FIELDS.includes(key as CommissionSettingsKeys)) &&
          String(Number(values[key]) * 100)) ||
        (values[key] && String(values[key])) ||
        '0';

      settings.push({
        key: key as CommissionSettingsKeys,
        value,
      });
    });

    if (isError) {
      return;
    }

    submitUpdateCommissionData({ settings });
  };

  const renderRow = ({ label, name, fixed, isSumma }: TFormField) => {
    const err = errors?.[name] || (fixed && errors?.[`${name}_fixed`]);

    return (
      <Form.Item key={name} label={label} required>
        <Row gutter={24} className={styles.row}>
          <Col span={3}>
            <Form.Item name={name}>
              <Input type='number' min={0} max={100} step={0.01} />
            </Form.Item>
          </Col>
          {isSumma ? <Col span={1}>₽</Col> : <Col span={1}>%</Col>}
          {fixed && (
            <>
              <Col span={2}>+</Col>
              <Col span={3}>
                <Form.Item name={`${name}_fixed`}>
                  <Input type='number' min={0} step={0.01} defaultValue={0} />
                </Form.Item>
              </Col>
              <Col span={1}>₽</Col>
            </>
          )}
        </Row>
        {err && <Typography.Text type='danger'>{err}</Typography.Text>}
      </Form.Item>
    );
  };

  useEffect(() => {
    initCommissionPage();
  }, []);

  useEffect(() => {
    form.setFieldsValue(commission);
  }, [form, commission]);

  return (
    <div className={styles.commission}>
      <Card>
        <Title>Установка комиссии</Title>

        {isLoading ? (
          <div className={styles.center}>
            <Spin size='large' />
          </div>
        ) : (
          <div>
            <Menu mode='horizontal' className={styles.menu} selectedKeys={[selectedTab]}>
              <Menu.Item key='/client' onClick={() => setSelectedTab('/client')}>
                <span>Клиент</span>
              </Menu.Item>
              <Menu.Item key='/posrednik' onClick={() => setSelectedTab('/posrednik')}>
                <span>Посредник</span>
              </Menu.Item>
              <Menu.Item key='/bank' onClick={() => setSelectedTab('/bank')}>
                <span>Банк</span>
              </Menu.Item>
            </Menu>
            <Form
              form={form}
              className={styles.form}
              name='basic'
              id='commissionForm'
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              onFinish={formSubmitHandler}
              autoComplete='off'
            >
              <div style={{ display: selectedTab === '/client' ? 'block' : 'none' }}>
                <span className={styles.subtitle}>Заказы в приложении allall</span>
                {FORM_FIELDS.client.mobileAppAll.map(renderRow)}
                <span className={styles.subtitle}>Заказы с сайта lk.allall.ru</span>
                {FORM_FIELDS.client.webLkAll.map(renderRow)}
              </div>

              <div style={{ display: selectedTab === '/posrednik' ? 'block' : 'none' }}>
                <span className={styles.subtitle}>Задания из приложения allall</span>
                {FORM_FIELDS.posrednik.mobileAppAll.map(renderRow)}
                <span className={styles.subtitle}>Задания с сайта lk.allall.ru</span>
                {FORM_FIELDS.posrednik.webLkAll.map(renderRow)}
                <span className={styles.subtitle}>Задания, проходящие через поставщиков seller.allall.ru</span>
                {FORM_FIELDS.posrednik.sellerAll.map(renderRow)}
              </div>
              <div style={{ display: selectedTab === '/bank' ? 'block' : 'none' }}>
                {FORM_FIELDS.bank.map(renderRow)}
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item label='Версия ФФД' name='ffd_version'>
                      <Select options={ffdSelectOptions} placeholder='Выберите версию ФФД' />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Button
                disabled={!commission}
                htmlType='submit'
                type='primary'
                className={styles.button}
                onClick={formSubmitHandler}
              >
                Сохранить изменения
              </Button>
            </Form>
          </div>
        )}
      </Card>
    </div>
  );
};
