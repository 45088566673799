import { ReportFilterData, Column } from 'src/pages/payments/paymentList/types';

import api from '../api';

export interface GetClientsPaymentsReqData {
  status?: string;
  order_id?: number;
  client_id?: number;
  init_at?: {
    from: string;
    to: string;
  };
  authorized_at?: {
    from: Date;
    to: Date;
  };
  confirmed_at?: {
    from: Date;
    to: Date;
  };
  amount?: number;
  bank_commission?: number;
  market_place_amount?: number;
  terminal_name?: string;
  pagination: {
    per_page: number;
    page: number;
  };
}

export interface GetLegalEntitiesPaymentsReqData {
  client_id?: number;
  order_id?: number;
  terminal_name?: string;
  init_at?: {
    from: string;
    to: string;
  };
  authorized_at?: {
    from: string;
    to: string;
  };
  confirmed_at?: {
    from: string;
    to: string;
  };
  amount?: number;
  bank_commission?: number;
  market_place_commission?: number;
  market_place_amount?: number;
  legal_entity_amount?: number;
  client_payment_id?: number;
  legal_entity_id?: number;
  pagination: {
    per_page: number;
    page: number;
  };
  columns?: {
    name: string;
    path: string;
  }[];
}

export interface GetReportReqData {
  type: 'clients' | 'legal-entities';
  payload: {
    uuid: string;
    filter: ReportFilterData;
    columns: Column[];
  };
}

type TGetPaymentMethodsResponse = {
  payment_methods: TPaymentMethodEntity[];
};

export type TPaymentMethodEntity = {
  id: number;
  name: string;
  type: number;
  commissions: [
    {
      type: number;
      value: number;
      applicant_id: number;
    },
  ];
};

export const getClientsPayments = (data: GetClientsPaymentsReqData) =>
  api.post('/payment-register/clients', data).then(response => response.data);

export const getLegalEntitiesPayments = (data: GetLegalEntitiesPaymentsReqData) =>
  api.post('/payment-register/legal-entities', data).then(response => response.data);

export const getPaymentsReport = (data: GetReportReqData) =>
  api.post(`/payment-register/${data.type}/report`, data.payload).then(response => response.data);

export const getPaymentMethods = (): Promise<TGetPaymentMethodsResponse> =>
  api.get('/payment-methods').then(response => response.data);
