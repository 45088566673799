import { DetailedTask, TaskTransfer, TIntermediary } from 'src/pages/task/types';
import { DateType } from 'src/shared/ui';
import { Client } from 'src/pages/profiles/clients/types';
import { ESupplierStatus } from 'src/pages/profiles/suppliers/types';
import { LegalEntity } from 'src/pages/profiles/legalEntities/types';
import { TPagination } from 'src/utils';

import api from '../api';

export interface TasksRequestData {
  code?: string;
  status?: number | string;
  statuses?: string[];
  count?: number | string;
  price?: number | string;
  client_id?: number | string;
  intermediary_id?: number | string;
  order_id?: number | string;
  created_at?: string;
  pagination: {
    per_page: number;
    page: number;
  };
  sorting?: {
    column: string;
    direction: string;
  };
}

export interface TransTaskReqData {
  id: string | number;
  data: TaskTransfer;
}

export interface TaskByCodeReqData {
  code: string;
}

export type TTaskItem = {
  id: number;
  code: string;
  status: number;
  status_name: string;
  count: number;
  price: number;
  client_id: number;
  intermediary_id: number;
  supplier_id: number;
  created_at: string;
  received_count: number;
  supplier_name: string;
  order_id: number;
  margin: number;
  total_price: number;
};

export type TTasksListResponse = {
  items: TTaskItem[];
  clients: Client[];
  intermediarys: TIntermediary[];
  suppliers: TSupplier[];
  pagination: TPagination;
};

export type TSupplier = {
  id: number;
  name: string;
  title: string;
  description: string;
  avatar: string;
  email: string;
  phone: string;
  inn: string;
  grn: string;
  ip: string;
  pass_number: string;
  pass_date: DateType;
  registration_date: DateType;
  status: ESupplierStatus;
  top_status: true;
  pavilions: TPavilion[];
  legalEntity: LegalEntity;
};

export type TPavilion = {
  id: number;
  name: string;
  line_id: number;
};

export type TMarkAsOutOfStockBody = {
  items: {
    id: number;
    count: number;
  }[];
};

export const getTasksList = (data: TasksRequestData) =>
  api.get('/tasks', { params: data }).then(response => response.data);

export const getTask = (id: string): Promise<DetailedTask> => api.get(`/task/${id}`).then(response => response.data);

export const transferTask = ({ id, data }: TransTaskReqData) =>
  api.post(`/task/${id}/transfer`, data).then(response => response.data);

export const getTasksQr = (id: string) => api.get(`/task/${id}/qr`).then(response => response.data);

export const getTaskByCode = (data: TaskByCodeReqData) =>
  api.get(`/task-by-code/${data.code}`).then(response => response.data);

export const getTaskStatuses = () => api.get('/task/statuses').then(response => response.data);

export const cancelIntermediary = (id: number) =>
  api.post(`/task/${id}/cancel-intermediary`).then(response => ({
    ...response.data,
    id,
  }));

export const changeStatus = (id: number) => api.post(`/task/${id}/revert-status`);

export const taskSupplierVerification = (id: number) =>
  api.post(`/task/${id}/supplier-verification`).then(response => ({
    ...response.data,
    id,
  }));

export const taskSupplierMovedPickupPoint = (id: number) =>
  api.post(`/task/${id}/supplier-moved-pickupPoint`).then(response => ({
    ...response.data,
    id,
  }));

export const markAsOutOfStock = (data: TMarkAsOutOfStockBody): Promise<void> =>
  api.post(`/tasks/mark-as-out-of-stock`, data).then(response => response.data);

export const productNotAvailable = (id: number): Promise<void> =>
  api.post(`/task/${id}/product-not-available`).then(response => response.data);
