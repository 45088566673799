import React, { useState } from 'react';
import { ConfirmForm } from 'src/shared/ui';
import { getNotification } from 'src/utils';
import { Form, Input } from 'antd';
import { tasksApi } from 'src/shared/api';
import { setError } from 'src/features/notifications';

import styles from './ChangeQuantityModal.module.less';
import { DetailedTask } from '../../types';

interface IProps {
  readonly onClose: () => void;
  readonly task?: DetailedTask;
}

export const ChangeQuantityModal: React.FC<IProps> = ({ onClose, task }) => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    const values = form.getFieldsValue();

    if (task?.task.count && +values.count > +task.task.count) {
      setError(`Значение не должно быть больше ${task?.task.count}`);
      return;
    }

    if (!task?.task.id || !+values.count) {
      return;
    }

    try {
      setLoading(true);
      await tasksApi.markAsOutOfStock({
        items: [
          {
            id: task?.task.id,
            count: +task.task.count - +values.count,
          },
        ],
      });
      window.location.reload();
    } catch (error) {
      getNotification({ error });
      setLoading(false);
    }
  };

  return (
    <ConfirmForm
      isOpen
      title='Изменить кол-во товаров в задании'
      closeHandler={onClose}
      confirmHandler={handleSubmit}
      buttonsPosition='left'
      titlePosition='left'
      width='500px'
      loading={loading}
    >
      <div className={styles.modalContent}>
        <span>
          Укажите количество товара, которое будет отправлено клиенту. Изменяйте количество только после согласования с
          клиентом
        </span>
        <Form form={form} layout='vertical' onFinish={handleSubmit}>
          <Form.Item
            name='count'
            label={`Количество товара, которое будет отправлено ${
              task?.task.count ? `(было заказано ${task?.task.count})` : ''
            }`}
          >
            <Input />
          </Form.Item>
        </Form>
      </div>
    </ConfirmForm>
  );
};
