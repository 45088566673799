import { Button, Col, Form, Input, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { setError, setSuccess } from 'src/features/notifications';
import { Product as TProduct } from 'src/pages/products/types';
import { productsApi } from 'src/shared/api';
import { MainModal } from 'src/shared/ui';

interface IProps {
  readonly product: TProduct;
  readonly onClose: () => void;
}

type TFormValues = {
  days: string;
};

export const PinTopModal: React.FC<IProps> = ({ product, onClose }) => {
  const [form] = Form.useForm<TFormValues>();
  const [loading, setLoading] = useState(false);
  const topUntilDate = dayjs(product.top_until);
  const isTop = topUntilDate > dayjs();

  const onFinish = (values: TFormValues) => {
    setLoading(true);

    const request = isTop
      ? productsApi.deleteProductTop(product.id)
      : productsApi.setProductTop({
          id: product.id,
          minutes: +values.days * 1440,
        });

    request
      .then(() => {
        window.location.reload();
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      });
  };

  return (
    <MainModal width='500px' isOpen closeHandler={onClose} title={isTop ? 'Открепить товар?' : 'Закрепление товара'}>
      <Form form={form} layout='vertical' onFinish={onFinish}>
        {isTop ? (
          <Typography.Title style={{ paddingBottom: '24px', color: '#597393' }} level={4}>
            Товар закреплён до {topUntilDate.format('DD.MM.YYYY HH:mm')}
          </Typography.Title>
        ) : (
          <Form.Item
            name='days'
            label='Количество дней'
            rules={[
              { required: true, message: 'Обязательное поле' },
              {
                validator: (_, value) => {
                  if (value && parseInt(value, 10) > 365) {
                    return Promise.reject(new Error('Максимум 365 дней'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input onChange={() => {}} />
          </Form.Item>
        )}
        <Row gutter={16} style={{ display: 'flex', alignItems: 'start' }}>
          <Col>
            <Form.Item shouldUpdate>
              {() => (
                <Button htmlType='submit' type='primary' disabled={loading} loading={loading}>
                  {isTop ? 'Подтвердить' : 'Сохранить'}
                </Button>
              )}
            </Form.Item>
          </Col>
          <Col>
            <Form.Item shouldUpdate>
              {() => (
                <Button onClick={onClose} disabled={loading}>
                  Отмена
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </MainModal>
  );
};
