import api from '../api';

export interface ProductsListRequestData {
  name?: string | null;
  category_id?: string;
  price?: string;
  supplier_id?: string;
  created_at?: Date;
  created_at_from?: Date;
  created_at_to?: Date;
  status?: 'active' | 'deactivated' | 'in_review';
  statuses?: string[];
  pagination: {
    per_page: number;
    page: number;
  };
}

export interface ActivityProductReqData {
  ids: string[];
}

export interface EditProductReqData {
  id: string | number;
  data: {
    name?: string;
    description?: string;
    supplier_id?: number;
    supplier_pavilion_id?: number;
    images?: string[];
    category_ids?: number[];
    offers?: [
      {
        price?: number;
        characteristics?: [
          {
            code?: 'size' | 'price' | 'picture';
            id?: number;
            value?: string;
          },
        ];
      },
    ];
    created_at?: string;
  };
}

export interface ComplaintProductReqData {
  id: number | string;
  data: {
    pagination: {
      per_page: number;
      page: number;
    };
  };
}

export type TSetProductTopRequest = {
  id: string;
  minutes: number;
}

export const getProductsList = (data: ProductsListRequestData) =>
  api.get('/products', { params: data }).then(response => response.data);

export const getComplaintsProductsList = (data: ProductsListRequestData) =>
  api.get('/products-with-complaints', { params: data }).then(response => response.data);

export const getProduct = (id: string) => api.get(`/product/${id}`).then(response => response.data);

export const deactivateProduct = (data: ActivityProductReqData) =>
  api.post('/product/deactivate', data).then(response => response.data);

export const activateProduct = (data: ActivityProductReqData) =>
  api.post('/product/activate', data).then(response => response.data);

export const editProduct = (data: EditProductReqData) =>
  api.put(`/product/${data.id}`, data.data).then(response => response.data);

export const getProductComplaint = (data: ComplaintProductReqData) =>
  api.post(`/product/${data.id}/complaints`, data.data).then(response => response.data);

export const setProductTop = ({id, minutes}: TSetProductTopRequest) =>
  api.post(`/product/${id}/top/${minutes}`).then(response => response.data);

export const deleteProductTop = (id: string) =>
  api.delete(`/product/${id}/top`).then(response => response.data);
